import { AdminEmployeePage } from "../components/admin/AdminEmployeePage";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";

export function AdminEmployee(){

    return(
        <div>
            <ManagerSidebar />
            <AdminEmployeePage />
        </div>
    )
}