import { HiCursorClick } from "react-icons/hi";
import keyboardLogo from "../../../images/keyboard-logo.png";
import { FiChevronLeft } from "react-icons/fi";
import DefaultWebcam from "../../../images/noCamshot.jpg"
import DefaultScreenshot from "../../../images/noScreenshot.jpg"
import { baseURL } from "../../api/env";
import { useMemo, useState } from "react";
import { fetchImageFromAPI } from "../../api/apiCalls";
import "./TimeCardModal.css";

function TimeCardModal(props) {
  const [screenshot, setScreenshot] = useState('')
  const [webcam, setWebcam] = useState('')



  const getProductivityColor = () => {
    
    let color, productivity = props.productivityType;
    if (productivity.toLowerCase() == "productive") {
      color = "#3CBE52";
    } else if (productivity.toLowerCase() == "overtime"){
      color = "#fcd257"
    }else if (productivity.toLowerCase() == "unproductive" || productivity.toLowerCase() == "ot discard") {
      color = "#C34D57";
    } else if (productivity.toLowerCase() == "marked") {
      color = "#FECD50";
    } else
    {
      color = "#B0BEC5";
    }

    return color;
  };

  useMemo(async () => {
    let scshot = props.screensrc === 'default'? DefaultScreenshot: await fetchImageFromAPI(`${baseURL}TrackingData/GetScreenshots?ImagePath=screenshots/` + props.screensrc + ".jpg")
    let wcam = props.camsrc === 'default' ? DefaultWebcam: await fetchImageFromAPI(`${baseURL}TrackingData/GetWebcam?ImagePath=webcam/`+ props.camsrc + ".jpg")
    setScreenshot(scshot)
    setWebcam(wcam)
  }, [])

  return (
    <div className="time-card-modal">
      <div className="timecard-time">{props.activityTime} | {props.project}</div>
      <div className="timeCard-row">
        <FiChevronLeft className="chevron" />
        <div className="screenshot-column">
          <img src={screenshot} alt="" />
          <div className="timecard-summary timeCard-row">
            <HiCursorClick className="key-logo" />
            <span className="mouse-clicks">Mouse Clicks: {props.mouse}</span>
            <img className="key-logo" src={keyboardLogo} alt="" />
            <span className="keystrokes">Key Strokes: {props.keys}</span>
            <span
              className="productivity-indicator"
              style={{ backgroundColor: getProductivityColor() }}
            >
              {props.productivityType}
            </span>
          </div>
        </div>
        <div className="camshot-column">
          <img src={webcam} alt="" />
        </div>
        <FiChevronRight className="chevron" />
      </div>
      <div className="other-images">
        <FiChevronLeft className="chevron" />
        <div className="other-image">
          <img src={screenshot} alt="" />
          <img src={screenshot} alt="" />
        </div>
        <div className="other-image">
          <img src={screenshot} alt="" />
          <img src={screenshot} alt="" />
        </div>
        <div className="other-image">
          <img src={screenshot} alt="" />
          <img src={screenshot} alt="" />
        </div>
        <div className="other-image">
          <img src={screenshot} alt="" />
          <img src={screenshot} alt="" />
        </div>
        <FiChevronRight className="chevron" />
      </div>
    </div>
  );
}

export default TimeCardModal;
