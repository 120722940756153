import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react"
import { get, put } from "../api/apiCalls";
import { AdminUserSettingsHeaderLink } from "../layout/header/AdminUserSettingsHeaderLink";
import { Box, Grid } from "@mui/material";
import { FaUpload, FaDownload, FaEdit } from "react-icons/fa";
import { baseURL } from '../api/env'
import { UploadResumeModal } from "./UploadResumeModal";
import { EditEmployeeModal } from "./EditEmployeeModal";
import axios from "axios";
import './AdminEmployeePage.css'

export function AdminEmployeePage(){
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [editEmployeeModal, setEditEmployeeModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getEmployees()
            } catch (error) {
              console.error("Error fetching employees:", error);
            }
          };
      
          fetchData();
    }, []);

    async function getEmployees(){
        const response = await get("Employee");
        setEmployees(response);
        setLoading(false)
    }

    const downloadResume = (fileName) => {
        fetch(`${baseURL}Employee/FetchResumeFromS3/${fileName}`, {
            method: 'GET',
        })
        .then((response) => {
            if (response.ok) {
            return response.blob();
            }
            throw new Error('Error downloading file');
        })
        .then((blob) => {
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const actionsBodyTemplate = (employee) => {
        return (
            <div className="employees-table-action-btns">
                <button title="Upload resume" onClick={() => handleOpenModal(employee)}><FaUpload /></button>
                <button onClick={() => downloadResume(employee.resume)} title="Download resume"><FaDownload /></button>
            </div>
        );
    };

    const editBodyTemplate = (employee) => {
        return (
            <div className="employees-table-edit-btns">
                <button title="Edit employee details" onClick={() => handleOpenEditEmployeeModal(employee)}><FaEdit /></button>
            </div>
        );
    };

    function handleOpenModal(employee){
        setSelectedEmployee(employee)
        setUploadFileModal(true)
    }
    
    function handleOpenEditEmployeeModal(employee){
        setSelectedEmployee(employee)
        setEditEmployeeModal(true)
    }

    async function onCloseEditEmployeeModal(){
        setEditEmployeeModal(false)
        await getEmployees()
    }

    function onCloseModal(){
        setUploadFileModal(false)
    }

    const footer = `In total there are ${employees ? employees.length : 0} employees.`;

    async function uploadResume(resume, employeeID){
        setLoading(true)
        const url = `${baseURL}Employee/UploadResumeToS3`;
        const formData = new FormData();
        formData.append('file', resume);
        formData.append('employeeID', employeeID);
        const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
        };
        axios.post(url, formData, config).then((response) => {
            setUploadFileModal(false)
            alert(response.data)
            getEmployees()
        }).catch((error) => {
            alert(error.response.data)
        });
    }

    async function updateEmployee(employee){
        let response = await put('Employee', employee)
        alert(response)
        onCloseEditEmployeeModal()
    }

    return(
        <div className="settingsContainer"> 
            <AdminUserSettingsHeaderLink/>
            {
                <Box sx={{flexGrow: 1}} className="settingsBody">
                    <Grid container>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">Employees</h3>
                        </Grid>
                        <Grid xs={11}>
                            {!loading && (
                                <DataTable value={employees} footer={footer}>
                                    <Column field="name" header="Name"></Column>
                                    <Column field="email" header="Email"></Column>
                                    <Column field="phoneNumber" header="Phone Number"></Column>
                                    <Column field="location" header="Location"></Column>
                                    <Column field="skills" header="Skills" style={{width: '10%'}}></Column>
                                    <Column field="languages" header="Languages" style={{width: '10%'}}></Column>
                                    <Column field="productTraining" header="Product Training" style={{width: '10%'}}></Column>
                                    <Column field="actions" header="Resume" body={actionsBodyTemplate}></Column>
                                    <Column field="edit" header="Edit" body={editBodyTemplate}></Column>
                                </DataTable>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            }
            {uploadFileModal && (
                <UploadResumeModal closeModal={onCloseModal} employee={selectedEmployee} upload={uploadResume}/>

            )}

            {editEmployeeModal && (
                <EditEmployeeModal employee={selectedEmployee} closeModal={onCloseEditEmployeeModal} update={updateEmployee}/>
            )}
        </div>
    )
}