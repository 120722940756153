import { useEffect, useState } from 'react'
import './UploadResumeModal.css'

export function UploadResumeModal({closeModal, employee, upload}){
    const [file, setFile] = useState()

    function handleResumeUpload(event){
        event.preventDefault()
        upload(file, employee.id)
    }
    
    function handleCloseModal(event){
        event.preventDefault()
        closeModal()
    }


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal(); 
            }
          };
      
          window.addEventListener('keydown', handleKeyDown);
      
          return () => {
              window.removeEventListener('keydown', handleKeyDown);
          };
    }, [closeModal])

    function handleResumeSelection(event){
        setFile(event.target.files[0])
        
    }

    return(
        <div className="resume-modal">
            <div className="resume-container">
                <form className="resume-form" onSubmit={handleResumeUpload} onReset={handleCloseModal}>
                    <label htmlFor="resume-input">File </label>
                    <input type="file" onChange={handleResumeSelection}/>
                    <button className='resume-form-upload-btn' type='submit' disabled={file === undefined}>Upload</button>
                    <button className='resume-form-cancel-btn' type='reset'>Cancel</button>
                </form>
                <small className='file-max-size-alert'>Extensions accepted: doc, docx and pdf, max size of 2mb.</small>
            </div>
        </div>
    )
}