import { NavLink } from "react-router-dom";
import './AdminUserSettingsHeaderLink.css'

export function AdminUserSettingsHeaderLink() {
  return (
    <div className="admin-subheader-links">
      <ul>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/user-settings/user-role">
            User Role
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/user-settings/trolley-report">
            Trolley Report
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/user-settings/update-rate">
            Update Rate
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/user-settings/additional-payments">
            Additional Payments
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/user-settings/employees">
            Employees
          </NavLink>
        </li>
      </ul>
    </div>
  );
}