import { useEffect, useState } from 'react'
import './EditEmployeeModal.css'
import { get } from '../api/apiCalls';
import { MultiSelect } from 'primereact/multiselect';
import { FloatLabel } from 'primereact/floatlabel';

export function EditEmployeeModal({employee, closeModal, update}){
    const [skills, setSkills] = useState([]);
    const [currentEmployeeSkills, setCurrentEmployeeSkills] = useState([])
    const [selectedSkills, setSelectedSkills] = useState([]);
    
    const [languages, setLanguages] = useState([]);
    const [currentEmployeeLanguages, setCurrentEmployeeLanguages] = useState([])
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    
    const [productTraining, setProductTraining] = useState([]);
    const [currentEmployeeProducts, setCurrentEmployeeProducts] = useState([])
    const [selectedProductTraining, setSelectedProductTraining] = useState([]);

    const[name, setName] = useState('')
    const[email, setEmail] = useState('')
    const[location, setLocation] = useState('')
    const[phone, setPhone] = useState('')

    async function getSkills(){
        let response = await get('Employee/Skills')
        setSkills(response)

        let employeeSkills = employee.skills.split(',').map(lang => lang.trim());
        const currentValue = response.filter(obj => 
            employeeSkills.map(lang => lang.toLowerCase()).includes(obj.skill.toLowerCase())
        );
        setCurrentEmployeeSkills(currentValue)
        setSelectedSkills(currentValue) 
    }
    
    async function getLanguages(){
        let response = await get('Employee/Languages');
        setLanguages(response);

        let employeeLanguages = employee.languages.split(',').map(lang => lang.trim());
        const currentValue = response.filter(obj => 
            employeeLanguages.map(lang => lang.toLowerCase()).includes(obj.language.toLowerCase())
        );
        setCurrentEmployeeLanguages(currentValue)
        setSelectedLanguages(currentValue);
    }
    
    async function getProductTraining(){
        let response = await get('Employee/ProductTraining')
        setProductTraining(response)
        
        let employeeProducts = employee.productTraining.split(',').map(lang => lang.trim());
        const currentValue = response.filter(obj => 
            employeeProducts.map(lang => lang.toLowerCase()).includes(obj.skill.toLowerCase())
        );
        setCurrentEmployeeProducts(currentValue)
        setSelectedProductTraining(currentValue) 
    }

    const hasChanged = () => selectedProductTraining === currentEmployeeProducts && selectedLanguages === currentEmployeeLanguages && selectedSkills === currentEmployeeSkills;

    function handleUpdateEmployee(event){
        event.preventDefault()

        let skillsIds = []
        selectedSkills.forEach((item) => {
            skillsIds.push(item.id)
        })

        let languagesIds = []
        selectedLanguages.forEach((item) => {
            languagesIds.push(item.id)
        })

        let productsIds = []
        selectedProductTraining.forEach((item) => {
            productsIds.push(item.id)
        })

        let model = {
            ID: employee.id,
            Name: name || employee.name,
            Email: email || employee.email,
            Location: location || employee.location,
            PhoneNumber: phone || employee.phoneNumber,
            Skills: skillsIds,
            Languages: languagesIds,
            ProductTraining: productsIds
        }
        update(model)
    }

    function handleEmail(event){
        setEmail(event.target.value)
    }

    function handelName(event){
        setName(event.target.value)
        
    }

    function handleLocation(event){
        setLocation(event.target.value)
        
    }

    function handlePhone(event){
        setPhone(event.target.value)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getSkills()
                await getLanguages()
                await getProductTraining()
            } catch (error) {
              console.error("Error fetching datas:", error);
            }
          };
      
          fetchData();

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal(); 
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
      
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [closeModal])

    return(
        <div className='edit-employee-modal'>
            <div className='edit-employee-container'>
                <form onSubmit={handleUpdateEmployee} onReset={closeModal} className='edit-employee-form'>
                    <div className='edit-employee-form-inputs'>
                        <div className='edit-employee-form-name-email'>
                            <div>
                                <label htmlFor='edit-employee-name'>Name</label>
                                <input type='text' id='edit-employee-name' defaultValue={employee.name} onChange={handelName}/>
                            </div>
                            <div>
                                <label htmlFor='edit-employee-email'>Email</label>
                                <input type='email' id='edit-employee-email' defaultValue={employee.email} onChange={handleEmail}/>
                            </div>
                        </div>

                        <div className='edit-employee-form-location-phone'>
                            <div>
                                <label htmlFor='edit-employee-location'>Location</label>
                                <input type='text' id='edit-employee-location' defaultValue={employee.location} onChange={handleLocation}/>
                            </div>
                            <div>
                                <label htmlFor='edit-employee-phone'>Phone Number</label>
                                <input type='tel' id='edit-employee-phone' defaultValue={employee.phoneNumber} onChange={handlePhone}/>
                            </div>
                        </div>
                        <div className='edit-employee-form-selects'>
                            <FloatLabel className='employee-form-select-input'>
                                <MultiSelect 
                                    value={selectedLanguages} 
                                    onChange={(e) => setSelectedLanguages(e.value)}
                                    options={languages} 
                                    id='language-dropdown'
                                    optionLabel="language" 
                                    placeholder="Select Languages" 
                                    maxSelectedLabels={5}  
                                    className='w-72'
                                />
                                <label htmlFor="language-dropdown">Languages</label>
                            </FloatLabel>

                            <FloatLabel className='employee-form-select-input'>
                                <MultiSelect 
                                    value={selectedSkills} 
                                    onChange={(e) => setSelectedSkills(e.value)} 
                                    options={skills} 
                                    id='skill-dropdown'
                                    optionLabel="skill" 
                                    placeholder="Select Skills" 
                                    maxSelectedLabels={5} 
                                    className='w-full'
                                />
                                <label htmlFor='skill-dropdown'>Skills</label>
                            </FloatLabel>

                            <FloatLabel className='employee-form-select-input test'>
                                <MultiSelect 
                                    value={selectedProductTraining} 
                                    onChange={(e) => setSelectedProductTraining(e.value)} 
                                    options={productTraining} 
                                    id='products-dropdown'
                                    optionLabel="skill" 
                                    placeholder="Select Products Training"
                                    maxSelectedLabels={5} 
                                    className='w-full'
                                />
                                <label htmlFor='products-dropdown'>Product Training</label>
                            </FloatLabel>

                        </div>
                        <div className='edit-employee-form-btns'>
                            <button 
                            className='edit-employee-save-btn' 
                            type='submit'
                            disabled={name === '' && email === '' && location === '' && phone === '' && hasChanged()}
                            >
                                Save
                            </button>
                            <button className='edit-employee-cancel-btn' type='reset'>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}