import { GrClose, GrAdd } from 'react-icons/gr';
import DataTableBase from '../../ui/DataTableBase.js';
import TextInput from '../../ui/TextInput.js';
import Autocomplete from '../../ui/Autocomplete.js';
import {post,get, put} from "../../api/apiCalls";
import React, { useState,useEffect} from 'react';
import { ReactSession } from 'react-client-session';
import Switch from "react-switch";
import './ProjectsModal.css';

function ProjectsModal(props) {
  let [myData,setMyData] = useState(props.modalData);
  let [employeeNames,setEmployeeNames] = useState([])
  let user = ReactSession.get("User");
  let [disabled,setDisabled] = useState(myData.disabled);
  let [managers, setManagers] = useState([]);
  let [manager, setManager] = useState(myData.manager);

  const toggleOnlyActive = () => {
    disabled = !disabled;
    setDisabled(disabled);
  }

  const [sharedService, setSharedService] = useState(myData.shared);

  function setShared() {
    setSharedService(!sharedService);
  }

  const getManagers = async() => {
    let response = await get("Users/GetManagers")
    setManagers(response)
  }

  const searchString =  async(search) => {
    let searchResults = [];
    if(search.length >= 3){
      let projectResult = await get("Users/SearchUsers?searchString="+search);
      
      if(projectResult !=null){
        for (const item of projectResult) {
          searchResults.push({
            name : item.firstName+ " " + item.lastname,
            id : item.id,
            username : item.username
          })
        }
        setEmployeeNames(searchResults);
      }
    }
  }

  const columns = [
    {
      id: 'agentname',
      name: 'Name',
      selector: (row) => row.agentname,
    },
    {
      id: 'removeagent',
      name: 'Remove Agent',
      selector: (row) => row.removeagent,
      cell: (row) => {
        return (
          <button className='remove-member' onClick={() => removeMember(row)}>
            Delete Member
          </button>
        );
      },
    },
  ];
  let chosenAgent = null;
  const  setNewMember = async(suggestion) => {
    chosenAgent = suggestion;
    
    
  }

  const handleManagerChange = (event) => {
    let selected = event.target.value.split(" - ")
    changeProjectDetails({...myData,managerId : selected[0], manager : selected[1] })
  }

  const addMember = async() => {
    if(chosenAgent!=null)
    {
      alert(chosenAgent.name + " added to " + myData.project);
      let keys = { agentUsername:chosenAgent.username , managerUsername: user.username , projectID : myData.id };
      await post("Projects/AssignAgent",keys);
      props.updateDetails(myData);
    }
  }

  const removeMember = async(data) => {
    
    let keys = { agentUsername:data.username , managerUsername: user.username , projectID : myData.id };
    await post("Projects/RemoveAgent",keys);
    
    props.updateDetails(myData);
  }

  const changeProjectDetails = async(value) => {
    setProjectData(value);
  }

  const updateProjectDetails = async(project) => {
    let keys = { 
      id : project.id, 
      hourCap : project.hours, 
      projectName: project.project, 
      contactName: project.contact, 
      disabled: disabled, 
      manager:project.manager,
      managerId:project.managerId
    };
    await put("Projects",keys);
    props.saveChanges();
  }

  useEffect(() => {
    getManagers()
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
      props.onCancel(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onCancel])

  return (
    <div className='form-modal project-modal' id='project-form-modal'>
      <div className='close-modal' onClick={props.onCancel}>
        <GrClose />
      </div>
      <div id='project-form'>
        <div className='project-details-card'>
          <div className='project-headers'>Project Details</div>
          <div className='project-card'>
            <TextInput
              type='text'
              required='required'
              text='Project Name'
              id='project-name'
              value={myData.project}
              onChangeText={(value) => changeProjectDetails({...myData,project : value})}
            />
            <TextInput
              type='text'
              required='required'
              text='Contact Name'
              id='project-contact'
              value={myData.contact}
              onChangeText={(value) => changeProjectDetails({...myData,contact : value})}
            />
            <TextInput
              type='number'
              required='required'
              id='project-hours'
              value={myData.hours}
              onChangeText={(value) => changeProjectDetails({...myData,hours : value})}
              text='Hour Cap Per Agent'
            />
            <div className='manager-select-box'>
              <label htmlFor='manager-selector'>Manager</label>
              <select id='manager-selector' className="manager-selector"onChange={handleManagerChange}>
                <option value={manager}>{manager}</option>
                {managers.map((manager) => (
                  <option key={manager.id} value={`${manager.id} - ${manager.firstName} ${manager.lastName}`}>
                    {manager.firstName} {manager.lastName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="project-details-options">
            <div className="disable-btn">
              <label htmlFor="project-details-onlyActive">Disable</label>
              <Switch onChange={() => toggleOnlyActive()} checked={disabled} />
            </div>
            <div className='project-details-input-with-label'>
              <label htmlFor='is-shared-project'>
                Shared
              </label>
              <input
                type='checkbox'
                id='is-shared-project'
                checked={sharedService ? 'checked' : ''}
                onChange={setShared}
              />
            </div>
          </div>
        </div>
        <div className='project-details-card'>
          <div className='project-team-headers'>Project Team Members</div>
          <div id='section-project-members'>
            <div id='add-project-member'>
              <Autocomplete
                suggestions={employeeNames}
                placeholderText='Add Member by User Name'
                searchString={searchString}
                addMember={setNewMember}

              />
              <div
                className='project-add-members'
                id='add-project-member-button'
                onClick={addMember}
              >
                <GrAdd />
              </div>
            </div>
            <DataTableBase data={myData.members} columns={columns} fileName={"Project Members"}/>
          </div>
        </div>
        <button  
          className='update-project-button'
          onClick={() => updateProjectDetails(myData)}
          >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default ProjectsModal;
